import { Connectors } from 'web3-react';

const RPC = {
  // BSC_TEST: { WS: '`wss://data-seed-prebsc-1-s2.binance.org:8545`' },
  BSC_TEST: { HTTP: 'https://speedy-nodes-nyc.moralis.io/af645988430caa6d17a560b5/bsc/testnet' },
  FTM_TEST: { HTTP: 'https://rpc.testnet.fantom.network' }
}

export const connecotrs = {
  MetaMask: new Connectors.InjectedConnector(),
  Network: new Connectors.NetworkOnlyConnector({ 
    providerURL: RPC.BSC_TEST.HTTP,
  })
}

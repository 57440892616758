import { useEffect, useMemo, useState } from 'react';
import { EventData } from 'web3-eth-contract';
import { batchIterator, batchPairs } from 'ebatch';
import { Button, Container, Divider, Dropdown, Form, Header, Loader, Segment } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect';
import { ALTER_IDS, DEPLOYMENT_BLOCK, useContractTower, useContractWndGameCR, useWeb3, useWeb3Context } from '../../../../../components/web3'
import { ITowerStake, safe, useEntityIds } from '../../../../../helpers';
import { calculateTowerState } from '../../../../../helpers';
import { Entity, EntityGroup } from '../../../../../components/entity';
import { ItemGroup } from '../../../../../components/item';
import { BuyChestSection } from './buy';
import { SellChestSection } from './sell';

type TokenId = number;


export const ChestsSection: React.FC = () => {

  return (
    <>
      <BuyChestSection />
      <Divider />
      <SellChestSection />
    </>
  )
}

import { useEffect, useMemo, useState } from 'react';
import { Button, Container, Dropdown, Form, Header, Loader, Segment } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect';
import { useContractTower, useContractWndGameCR, useWeb3, useWeb3Context } from '../../../../components/web3'
import { safe, useEntityIds } from '../../../../helpers';

export const StakeSection: React.FC = () => {

  const context = useWeb3Context();
  const contract = useContractTower();
  const entities = useEntityIds();

  const [data, setData] = useState<string[]>([]);

  const options = useMemo(() => {
    return entities.ids.map((id) => ({
      key: `nft_${id}`,
      text: `#${id}`,
      value: id,
    }));
  }, [entities.ids]);

  function stake() {
    return contract.methods.addManyToTowerAndFlight(context.account, data).send();
  }

  if (entities.loading) {
    return <Loader active />;
  }

  return (
    <Form>
      <Header>Stake</Header>
      <Form.Dropdown
        placeholder='NFTs'
        fluid
        multiple
        selection
        options={options}
        onChange={(_, { value }) => setData(value as string[])}
      />
      <Button onClick={safe(stake)}>Execute</Button>
    </Form>
  )
}

import { useEffect, useState } from 'react';
import { Button, Container, Segment } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect';
import { ADMIN, applyDecimals, removeDecimals, useContractToken, useWeb3, useWeb3Context } from '../../components/web3'
import { asAdmin, safe } from '../../helpers';

const AMOUNT = 1e6;

export const TokenInfo: React.FC = () => {

  const web3 = useWeb3();
  const context = useWeb3Context();
  const [balance, setBalance] = useState('-');
  const contract = useContractToken();

  useAsyncEffect(async () => {
    const raw = await contract.methods.balanceOf(context.account).call();
    const decimals = await contract.methods.decimals().call();
    const formated = removeDecimals(raw, decimals);
    setBalance(formated);
  })

  const GimmeMoney = async () => {
    const decimals = await contract.methods.decimals().call();
    const rawAmount = applyDecimals(AMOUNT, decimals);
    const method = contract.methods.mint(context.account, rawAmount);
    await asAdmin(method);
  }

    ;
  return (
    <Segment>
      <p>Token balance: {balance}</p>
      <Button onClick={safe(GimmeMoney)}>Faucet {AMOUNT}</Button>
    </Segment>
  )
}

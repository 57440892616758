import { useMemo, useState } from 'react';
import { List, Form, Header, Button } from 'semantic-ui-react';
import useAsyncEffect from 'use-async-effect';
import { useWeb3Context } from 'web3-react';
import { useWeb3, useContractWndGameCR, applyDecimals, useContractWnD, removeDecimals, useContractToken } from '../../../../components/web3';
import { safe } from '../../../../helpers';
import { Big } from 'big.js';

Big.PE = Infinity;
export const SacrificeSection: React.FC = () => {

  const context = useWeb3Context();
  const web3 = useWeb3();
  const cGame = useContractWndGameCR();
  const cToken = useContractToken();
  const cNft = useContractWnD();
  const [data, setData] = useState<any>({});
  const [mintCost, setMintCost] = useState<string>();

  useAsyncEffect(async (isM) => {
    const [minted, max, decimals] = await Promise.all([
      cNft.methods.minted().call(),
      cNft.methods.maxTokens().call(),
      cToken.methods.decimals().call(),
    ]);
    const rawResult = await cGame.methods.mintCost(minted, max).call();
    const result = removeDecimals(rawResult, decimals);
    if (isM()) {
      setMintCost(result);
    }
  }, []);

  const sacrifice = async () => {
    const decimals = await cToken.methods.decimals().call();
    const rawAmount = applyDecimals(data.amount, decimals);
    return cGame.methods.sacrifice(data.entityId, rawAmount).send();
  }

  const costs = useMemo(() => {
    if (!mintCost) {
      return [];
    }
    const bMintCost = Big(mintCost);
    const wCost = bMintCost.times(3);
    const dCost = bMintCost.times(4);
    return [
      <li key='w'>Wizzard: {wCost.toString()}</li>,
      <li key='d'>Dragon: {dCost.toString()}</li>
    ];
  }, [mintCost]);
  console.log('cc', costs)

  return (
    <Form>
      <Header>Sacrifice</Header>
      <p>Base cost is {mintCost}</p>
      <p>Sacrificing cost:</p>
      <ul>{costs}</ul>
      <Form.Input
        label='Entity ID (not item)'
        value={data.entityId ?? ''}
        onChange={(_, { value }) => setData({ ...data, entityId: value })}
      />
      <Form.Input
        label='Token Amount'
        value={data.amount ?? ''}
        onChange={(_, { value }) => setData({ ...data, amount: value })}
      />
      <Button onClick={safe(sacrifice)}>Execute</Button>
    </Form>
  )
}

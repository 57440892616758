import { UserPage } from './pages';
import { Web3Context } from './components/web3';
import { AppHeader } from './components/app-header';

function App() {
  return (
    <Web3Context>
      <AppHeader />
      <UserPage />
    </Web3Context>
  );
}

export default App;

import React from 'react';
import Web3 from 'web3';
import Web3Provider from 'web3-react'
import { connecotrs } from './connectors';
import { Web3Init } from './init';

export const Web3Context: React.FC = (props) => {

  return (
    <Web3Provider
      connectors={connecotrs}
      libraryName='web3.js'
      web3Api={Web3}
    >
      <Web3Init>
        {props.children}
      </Web3Init>
    </Web3Provider>
  )

}

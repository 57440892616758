import Web3 from 'web3';
import { ADMIN } from '../components/web3';

export async function asAdmin(method: any) {
  const web3 = new Web3(method._parent.givenProvider);
  const contract = method._parent.options.address;
  const [limit, price] = await Promise.all([
    method.estimateGas({ from: ADMIN.ADDRESS }),,
    web3.eth.getGasPrice(),
  ]);
  const abi = method.encodeABI();

  const txObject = {
    from: ADMIN.ADDRESS,
    data: abi,
    to: contract,
    gas: limit,
    gasPrice: price,
  }
  // console.log('txConfig', txObject)
  const sender = web3.eth.accounts.privateKeyToAccount(ADMIN.PK);
  const signedTx = await sender.signTransaction(txObject);
  const tx = await web3.eth.sendSignedTransaction(signedTx.rawTransaction!);
  return tx;
}

export { }

/*
import Web3 from 'web3';
import { ADMIN } from '../components/web3';
interface IExecuteMethod {
  web3: Web3,
  method: any,
  pk: string
}
// export async function asAdmin({ method, web3, pk }: IExecuteMethod) {
  export async function asAdmin(method: any) {
  const [limit, price] = await Promise.all([
    method.estimateGas({ from: ADMIN.ADDRESS }),,
    // web3.eth.getGasPrice(),
  ]);
  return method.send({
    from: ADMIN.ADDRESS,
    gas: limit,
    // gasPrice: price,
  });
  // const txObject = {
  //   from: sender.address,
  //   data: abi,
  //   to: contract.options.address,
  //   gas: limit,
  //   gasPrice: price,
  // }
  // console.log('txConfig', txObject)
  // const signedTx = await sender.signTransaction(txObject);
  // const tx = await web3.eth.sendSignedTransaction(signedTx.rawTransaction!);
  // return tx;
}
*/

/*
import Web3 from 'web3';
import { ADMIN } from '../components/web3';

export async function asAdmin(web3: Web3, method: any) {

  // checks if admin account is in keystore, only for development
  let isIn = false;
  for (let i = 0; i < web3.eth.accounts.wallet.length; i += 1) {
    const account = web3.eth.accounts.wallet[i];
    if (account.address === ADMIN.ADDRESS) {
      isIn = true;
      break;
    }
  }
  if (!isIn) {
    web3.eth.accounts.wallet.add(ADMIN.PK);
  }

  const [limit, price, nonce] = await Promise.all([
    method.estimateGas({ from: ADMIN.ADDRESS }), ,
    web3.eth.getGasPrice(),
    web3.eth.getTransactionCount(ADMIN.ADDRESS),
  ]);
  console.log('wtf', nonce);
  const tx = {
    from: ADMIN.ADDRESS,
    gas: limit,
    gasPrice: price,
    nonce,
  };
  console.log('as admin', tx);
  return method
    .send(tx)
    .once('transactionHash', (txHash: string) => {
      console.log('txHash', txHash)
    });
}
 */

export * from './entity.helper';
export * from './tx.helper';
export * from './tower.helper';

export function safe(fn: Function) {
  return async () => {
    try {
      console.log(`[${fn.name}] Started`);
      const result = await fn();
      console.log(`[${fn.name}] Succeed`, result);
      return result;
    } catch (error) {
      console.error(`[${fn.name}] Failed`, error);
    }
  }
}

import { useEffect, useMemo, useState } from 'react';
import { Image, Card, CardProps, Container, Header, Loader, Segment } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect';
import { useContractAlter, useContractWnD, useWeb3Context } from '../web3';

interface IMetadata {
  attributes: any[];
  name: string;
  description: string;
  image: string;
}

// a lib can be used here?
const APP_JSON_B64 = 'data:application/json;base64,';
async function decodeMetadata(str: string): Promise<IMetadata> {
  const result = await fetch(str);
  return result.json();
}


interface IProps {
  id: number;
  overide?: CardProps;
}
export const Entity: React.FC<IProps> = ({ id, overide }) => {

  const context = useWeb3Context();
  const contract = useContractAlter();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IMetadata>();
  const [balance, setBalance] = useState<string>();

  useAsyncEffect(async (isMounted) => {
    console.log(contract)
    const [uri, balance] = await Promise.all([
      contract.methods.uri(id).call(),
      contract.methods.balanceOf(context.account, id).call(),
    ]);
    const metadata = await decodeMetadata(uri);
    if (isMounted()) {
      setData(metadata);
      setBalance(balance);
      setLoading(false);
    }
  }, [])

  if (loading) {
    return (
      <Card>
        <Card.Content>
          <Loader active={true} />
        </Card.Content>
      </Card>
    )
  }

  if (!data) {
    return (
      <> error </>
    )
  }

  return (
    <Card>
      <Card.Content>
        <Image
          floated='right'
          size="mini"
          src={data.image}
        />
        <Card.Header>{data.name}</Card.Header>
        <Card.Meta>id #{id}</Card.Meta>
      </Card.Content>
      <Card.Content extra>
        Balance: {balance}
      </Card.Content>
    </Card>
    // <Card 
      
    //   header={data.name}
    //   // description={data.description} // it's a constants
    //   image={data.image}
    //   meta={`id #${id}`}
    //   description={`Balance: ${balance}`}
    //   {...(overide ?? {})}
    // />
  );
}

export const ItemGroup: React.FC<{ids: number[] }> = ({ ids }) => {
  const group = useMemo(() => {
    if (!ids.length) {
      return (
        <>You don't own any items</>
      )
    }
    const entities = ids.map((id) => <Entity key={`entity_${id}`} id={id}/>);
    return (
      <Card.Group stackable>
        {entities}
      </Card.Group>
    )
  }, [ids]);
  
  return group;
}

import { Segment, Header, Divider } from 'semantic-ui-react'
import { StateSection } from './state'
import { StakeSection } from './stake'
import { ClaimSection } from './claim'

export const TowerSection: React.FC = () => {
  return (
    <>
      <StateSection />
      <Divider />
      <StakeSection />
      <Divider />
      <ClaimSection />
    </>
  )
}

import { Segment } from 'semantic-ui-react'
import { useWeb3Context } from 'web3-react'

export const AppHeader: React.FC = () => {

  const context = useWeb3Context()

  return (
    <Segment>
      Address: {context.account}
    </Segment>
  )
}

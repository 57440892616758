import { useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { useContractWnD, useWeb3Context } from '../components/web3';

export const useEntityIds = () => {
  const context = useWeb3Context();
  const contract = useContractWnD();
  const account = context.account;

  const [loading, setLoading] = useState(true);
  const [ids, setIds] = useState<number[]>([]);

  useAsyncEffect(async (isMounted) => {
    const count = await contract.methods.balanceOf(account).call();
    // TODO: use batch here
    const promises = [];
    for (let i = 0; i < count; i += 1) {
      const promise = contract.methods.tokenOfOwnerByIndex(account, i).call();
      promises.push(promise);
    }
    const newIds = await Promise.all(promises);
    if (isMounted()) {
      setIds(newIds);
      setLoading(false);
    }
  }, [context])

  return { loading, ids };

}

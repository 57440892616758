import { useEffect, useMemo, useState } from 'react';
import { Button, Container, Dropdown, Form, Header, Loader, Segment } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect';
import { useContractTower, useContractWndGameCR, useWeb3, useWeb3Context } from '../../../../components/web3'
import { safe, useEntityIds } from '../../../../helpers';

export const ClaimSection: React.FC = () => {
  const contract = useContractTower();

  const [data, setData] = useState({
    ids: [] as string[],
    unstake: false,
  });


  function claim() {
    return contract.methods.claimManyFromTowerAndFlight(data.ids, data.unstake).send();
  }

  return (
    <Form>
      <Header>Claim</Header>
      <Form.Input
        label='NFT ids (separated by space)'
        value={data.ids.join(' ')}
        onChange={(_, { value }) => setData({ ...data, ids: value.trim().split(' ') })}
      />
      <Form.Radio
        toggle
        label='Unstake'
        check={data.unstake}
        onChange={(_, { checked }) => setData({ ...data, unstake: !!checked })}
      />
      <Button onClick={safe(claim)}>Execute</Button>
    </Form>
  )
}

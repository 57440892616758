import { useEffect, useMemo, useState } from 'react';
import { Button, CheckboxProps, Container, Divider, Form, Header, InputOnChangeData, Message, Segment, Tab } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect';
import { useContractWndGameCR, useWeb3, useWeb3Context } from '../../../../components/web3'
import { safe } from '../../../../helpers';


export const CommitSection: React.FC = () => {
  const contract = useContractWndGameCR();
  const context = useWeb3Context();

  const [data, setData] = useState({ amount: '1', stake: false });
  const [hasMintPending, setHasMintPending] = useState<boolean>();

  function mintCommit() {
    // validation and errors
    return contract.methods.mintCommit(data.amount, data.stake).send();
  };

  useAsyncEffect(async (isMounted) => {
    const hasMintPending = await contract.methods.hasMintPending(context.account).call();
    console.log('hasMintPending', hasMintPending)
    if (isMounted()) {
      setHasMintPending(hasMintPending);
    }
  }, []);

  const disabled = useMemo(() => {
    // TODO: check pending mint amount (but it's private for now)
    return hasMintPending;
  }, [hasMintPending]);

  return (
    <Form warning>
      <Header>Commit</Header>
      <Form.Input
        name="amount"
        label="Amount"
        value={data.amount}
        onChange={(_, { value }) => setData({ ...data, amount: value })}
      />
      <Form.Radio
        checked={data.stake}
        label={'Auto stake after mint'}
        onChange={(_, { checked }) => setData({ ...data, stake: !!checked })}
        slider
      />
      {disabled && (
        <Message warning>
          <Message.Header>You can not mint at this moment</Message.Header>
          {hasMintPending && <p>You already have pending mint</p>}
        </Message>
      )}
      <Button disabled={disabled} onClick={safe(mintCommit)}>Execute</Button>
    </Form>
  )
}

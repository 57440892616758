import { useEffect, useMemo, useState } from 'react';
import { EventData } from 'web3-eth-contract';
import { batchIterator, batchPairs } from 'ebatch';
import { Button, Container, Divider, Dropdown, Form, Header, Loader, Segment } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect';
import { ALTER_IDS, DEPLOYMENT_BLOCK, useContractAlter, useContractTower, useContractWndGameCR, useWeb3, useWeb3Context } from '../../../../../components/web3'
import { ITowerStake, safe, useEntityIds } from '../../../../../helpers';
import { calculateTowerState } from '../../../../../helpers';
import { Entity, EntityGroup } from '../../../../../components/entity';
import { ItemGroup } from '../../../../../components/item';

type TokenId = number;


export const SellChestSection: React.FC = () => {

  const context = useWeb3Context();
  const web3 = useWeb3();
  const contract = useContractWndGameCR();
  const [data, setData] = useState<any>({});

  const sell = () => {
    return contract.methods.sellTreasureChests(data.amount).send();
  }

  return (
    <Form>
      <Header>Sell Treasure Chest</Header>
      <Form.Input
        label='Amount'
        value={data.amount ?? ''}
        onChange={(_, { value }) => setData({ ...data, amount: value})}
      />
      <Button onClick={safe(sell)}>Execute</Button>
    </Form>
  )
}

import { useEffect, useMemo, useState } from 'react';
import { Button, Container, Divider, Header, Message, Segment, Tab } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect';
import { useContractWndGameCR, useWeb3, useWeb3Context } from '../../../../components/web3'
import { safe } from '../../../../helpers';

export const RevealSection: React.FC = () => {

  const contract = useContractWndGameCR();
  const context = useWeb3Context();
  const [canReveal, setCanReveal] = useState<boolean>();
  const [hasMintPending, setHasMintPending] = useState<boolean>();

  function mintReveal() {
    return contract.methods.mintReveal().send();
  }

  useAsyncEffect(async (isMounted) => {
    // seems contract method name is wrong
    const [canReveal, hasMintPending] = await Promise.all([
      contract.methods.canMint(context.account).call(),
      contract.methods.hasMintPending(context.account).call(),
    ]);
    if (isMounted()) {
      setCanReveal(canReveal);
      setHasMintPending(hasMintPending);
    }
  }, []);

  const disabled = useMemo(() => {
    return !canReveal;
  }, [canReveal]);

  return (
    <>
      <Header>Reveal</Header>
      <p>Reveals all entities.</p>
      <p>Should be called after admin makes random commit.</p>
      {disabled && (
        <Message warning>
          <Message.Header>You cannot reveal at this moment</Message.Header>
          {!hasMintPending && <p>You have no pending mints</p>}
          {!canReveal && hasMintPending && <p>Random seed has not been set yet</p>}
        </Message>
      )}
      <Button disabled={disabled} onClick={safe(mintReveal)}>Execute</Button>
    </>
  )
}

import { useEffect, useMemo, useState } from 'react';
import { EventData } from 'web3-eth-contract';
import { batchIterator, batchPairs } from 'ebatch';
import { Button, Container, Dropdown, Form, Header, Loader, Segment } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect';
import { ALTER_IDS, DEPLOYMENT_BLOCK, useContractTower, useContractWndGameCR, useWeb3, useWeb3Context } from '../../../../components/web3'
import { ITowerStake, safe, useEntityIds } from '../../../../helpers';
import { calculateTowerState } from '../../../../helpers';
import { Entity, EntityGroup } from '../../../../components/entity';
import { ItemGroup } from '../../../../components/item';

type TokenId = number;


export const StateSection: React.FC = () => {

  const context = useWeb3Context();
  const web3 = useWeb3();
  const contract = useContractTower();
  const [accountStakes, setAccountStakes] = useState<ITowerStake[]>([]);


  // useAsyncEffect(async (isMounted) => {
  //   const items = [];
  //   await Promise.all(ALTER_IDS.map(async (id) => {
  //     const balance = await contract.methods.balanceOf(context.account).call();
  //     console.log(balance);
  //   }));
  //   if (isMounted()) {
  //     // setAccountStakes(accountStakes);
  //   }

  // }, []);

  return (
    <>
      <Header>State:</Header>
      <Header sub>
        Your staked NFTs:
      </Header>
      <ItemGroup ids={ALTER_IDS} />
    </>
  )
}

import { useMemo, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { Big } from 'big.js';
import { Card, CardProps } from 'semantic-ui-react';
import useAsyncEffect from 'use-async-effect';
import { Entity } from '../../../../components/entity';
import { removeDecimals, useContractToken, useContractTower, useWeb3 } from '../../../../components/web3';
import { ITowerStake } from '../../../../helpers';

export const StakesSection: React.FC<{ stakes: ITowerStake[] }> = (props) => {

  return (
    <Card.Group>
      {props.stakes.map((stake) => <AStake key={`stake_${stake.tokenId}`} {...stake} />)}
    </Card.Group>
  )

}

const Timer: React.FC<{ ts: number }> = (p) => {

  const expire = new Date(new Date().getTime() + p.ts * 1e3);
  const { days, hours, seconds, minutes } = useTimer({ expiryTimestamp: expire });
  return (
    <>
      {days} days and {hours}:{minutes}:{seconds}
    </>
  );

}

export const AStake: React.FC<ITowerStake> = (props) => {

  const web3 = useWeb3();
  const tower = useContractTower();
  const token = useContractToken();

  const [claimDate, setClaimTs] = useState<number>();
  const [now, setNow] = useState<number>();
  const [rewards, setRewards] = useState<string>();

  useAsyncEffect(async (isMounted) => {
    const [now, stakedAt, minStakePeriod] = await Promise.all([
      web3.eth.getBlock('latest').then(block => Number(block.timestamp)),
      web3.eth.getBlock(props.blockHash).then(block => Number(block.timestamp)),
      tower.methods.MINIMUM_TO_EXIT().call().then(Number),
    ]);
    console.log({ stakedAt, minStakePeriod, now})
    const newClaimTs = stakedAt + minStakePeriod;
    if (isMounted()) {
      setClaimTs(newClaimTs);
      setNow(now);
    }
  }, []);

  useAsyncEffect(async (isMounted) => {
    const [rawRewards, decimals, claimFee] = await Promise.all([
      tower.methods.calculateRewards(props.tokenId).call(),
      token.methods.decimals().call(),
      tower.methods.GP_CLAIM_TAX_PERCENTAGE().call().then(Number),
    ]);
    const bCoef = Big(100).minus(claimFee).div(100);
    const newRewards = removeDecimals(bCoef.times(rawRewards), decimals);
    if (isMounted()) {
      setRewards(newRewards);
    }
  }, []);

  const ClaimPart = useMemo(() => {
    if (claimDate && now) {
      if (claimDate > now) {
        return <p>Can be claimed in <Timer ts={claimDate - now} /></p>;
      } else {
        return <p>Can be claimed!</p>
      }
    }
  }, [claimDate, now]);

  const RewardPart = useMemo(() => {
    if (rewards) {
      return <p>Current rewards: {rewards}</p>;
    }
  }, [rewards]);

  const overide: CardProps = {
    description: ClaimPart,
    extra: RewardPart
  }

  return (
    <Entity
      id={props.tokenId}
      overide={overide}
    />
  )

}

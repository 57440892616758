import { useEffect, useState } from 'react';
import { Button, Container, Form, Header, Input, InputOnChangeData, Segment } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect';
import { ADMIN, applyDecimals, removeDecimals, useContractToken, useContractWndGameCR, useWeb3, useWeb3Context } from '../../../components/web3'
import { safe, asAdmin } from '../../../helpers';

const SEED = '0x953c77342ffda000000000000000000000000000000000000000000000000000';

export const AdminSection: React.FC = () => {

  // use form hooks to handle progress
  const web3 = useWeb3();
  const contract = useContractWndGameCR();

  const [data, setData] = useState({ seed: SEED });

  const handleChange = (_: unknown, { name, value }: InputOnChangeData) => setData({ ...data, [name]: value });

  async function addCommitRandom() {
    const { seed } = data;
    // validation
    const method = contract.methods.addCommitRandom(seed);
    const tx = await asAdmin(method);
    return tx;
  };


  return (
    <>
      <Form>
        <Header>WndGameCR.addCommitRandom</Header>
        <Form.Input
          name="seed"
          label="Seed"
          value={data.seed}
          onChange={handleChange}
          fluid
        />
        <Button onClick={safe(addCommitRandom)}>Execute</Button>
      </Form>
    </>
  )
}

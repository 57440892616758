import { Container } from 'semantic-ui-react'
import { Web3Context } from '../components/web3/context'
import { GameSection } from './game'
import { TokenInfo } from './token'

export const UserPage: React.FC = () => {
  return (
      <Container>
        hey
        <TokenInfo />
        <GameSection />
      </Container>
  )
}

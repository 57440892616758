import { useEffect, useMemo, useState } from 'react';
import { EventData } from 'web3-eth-contract';
import { batchIterator, batchPairs } from 'ebatch';
import { Button, Container, Dropdown, Form, Header, Loader, Segment } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect';
import { DEPLOYMENT_BLOCK, useContractTower, useContractWndGameCR, useWeb3, useWeb3Context } from '../../../../components/web3'
import { ITowerStake, safe, useEntityIds } from '../../../../helpers';
import { calculateTowerState } from '../../../../helpers';
import { Entity, EntityGroup } from '../../../../components/entity';
import { StakesSection } from './stakes';

type TokenId = number;


export const StateSection: React.FC = () => {

  const context = useWeb3Context();
  const web3 = useWeb3();
  const contract = useContractTower();
  const [accountStakes, setAccountStakes] = useState<ITowerStake[]>([]);


  useAsyncEffect(async (isMounted) => {
    const stakesMap = await calculateTowerState(web3, contract);
    const accountStakes: ITowerStake[] = [];
    for (const [_, stake] of stakesMap) {
      if (stake.owner === context.account) {
        accountStakes.push(stake);
      }
    }
    if (isMounted()) {
      setAccountStakes(accountStakes);
    }

  }, []);

  const tokenIds = useMemo(() => {
    const ids = accountStakes.map(({ tokenId }) => tokenId);
    return ids;
  }, [accountStakes])

  return (
    <>
      <Header>State:</Header>
      <Header sub>
        Your staked NFTs:
      </Header>
      <StakesSection stakes={accountStakes} />
    </>
  )
}

import { useEffect, useMemo, useState } from 'react';
import { Image, Card, CardProps, Container, Header, Loader, Segment } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect';
import { useContractWnD } from '../web3';

interface IMetadata {
  attributes: any[];
  name: string;
  description: string;
  image: string;
}

// a lib can be used here?
const APP_JSON_B64 = 'data:application/json;base64,';
async function decodeMetadata(str: string): Promise<IMetadata> {
  const result = await fetch(str);
  return result.json();
}


interface IProps {
  id: number;
  overide?: CardProps;
}
export const Entity: React.FC<IProps> = ({ id, overide }) => {

  const contract = useContractWnD();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IMetadata>();

  useAsyncEffect(async (isMounted) => {
    const uri = await contract.methods.tokenURI(id).call();
    const metadata = await decodeMetadata(uri);
    if (isMounted()) {
      setData(metadata);
      setLoading(false);
    }
  }, [])

  if (loading) {
    return (
      <Card>
        <Card.Content>
          <Loader active={true} />
        </Card.Content>
      </Card>
    )
  }

  if (!data) {
    return (
      <> error </>
    )
  }

  return (
    // <Card 
    //   header={data.name}
    //   // description={data.description} // it's a constants
    //   image={data.image}
    //   meta={`id #${id}`}
    //   {...(overide ?? {})}
    // />
    <Card>
      <Card.Content>
        <Image
          floated='right'
          size="tiny"
          src={data.image}
        />
        <Card.Header>{data.name}</Card.Header>
        <Card.Meta>id #{id}</Card.Meta>
        {overide?.description && (
          <Card.Description>{overide.description}</Card.Description>
        )}
      </Card.Content>
      {overide?.extra && (
        <Card.Content extra>
          {overide.extra}
        </Card.Content>
      )}
  </Card>
  );
}

export const EntityGroup: React.FC<{ids: number[] }> = ({ ids }) => {
  const group = useMemo(() => {
    if (!ids.length) {
      return (
        <>You don't own any tokens</>
      )
    }
    const entities = ids.map((id) => <Entity key={`entity_${id}`} id={id}/>);
    return (
      <Card.Group stackable>
        {entities}
      </Card.Group>
    )
  }, [ids]);
  
  return group;
}

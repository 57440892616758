import { useEffect, useMemo, useState } from 'react';
import { Button, Container, Divider, Header, Message, Segment, Tab } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect';
import { useContractWndGameCR, useWeb3, useWeb3Context } from '../../../../components/web3'
import { safe } from '../../../../helpers';

interface IPendingMint {
  amount: number;
  stake: boolean;
}

export const StateSection: React.FC = () => {

  const contract = useContractWndGameCR();
  const context = useWeb3Context();
  const [pendingMint, setPendingMint] = useState<IPendingMint>();

  useAsyncEffect(async (isMounted) => {
    // seems contract method name is wrong
    const hasMintPending = await contract.methods.hasMintPending(context.account).call();
    if (!hasMintPending) {
      return;
    }
    const pendingMint = await contract.methods.getPendingMint(context.account).call();
    if (isMounted()) {
      setPendingMint(pendingMint);
    }
  }, []);

  console.log(pendingMint);

  const content = pendingMint ? (
    <>
      <p>Amount of tokens to mint: {pendingMint.amount}</p>
      <p>Autostake to tower: {pendingMint.stake.toString()}</p>
    </>
  ) : <>You don't have a pending mint</>

  return (
    <>
      <Header>Pending Mint</Header>
      {content}
    </>
  )
}

import { Segment, Header, Divider } from 'semantic-ui-react'
import { ChestsSection } from './chests'
import { SacrificeSection } from './sacrifice'
import { StateSection } from './state'

export const AlterSection: React.FC = () => {
  return (
    <>
      <StateSection />
      <Divider />
      <ChestsSection />
      <Divider />
      <SacrificeSection />
      <Divider />
      // TODO: PAY TRIBUTE 
    </>
  )
}

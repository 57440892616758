import Web3 from 'web3';
import { Big, BigSource } from 'big.js';
import { Contract } from 'web3-eth-contract';
import { useWeb3Context } from 'web3-react'
import { TOKEN_ABI } from '../../abi/Token';
import { TOWER } from '../../abi/Tower';
import { WND } from '../../abi/WnD';
import { WND_GAME_CR } from '../../abi/WndGameCR';
import { SACRIFICIAL_ALTER_ABI } from '../../abi/SacrificialAlter';

// use enums for contract names
const ADDRESS = {
  "GP": "0xA8164Fc9ee19fF091a4D96d69927a5C4371ffdBD",
  "Tower": "0xBA50961E4fFD782134601eb156bBD0C374F0A2A5",
  "SacrificialAlter": "0x999Ed750283F5AF05d6469d0181881a0E4Bc278D",
  "Traits": "0x884F5ADF311882BEa98ed353DB14B2848381229d",
  "WnD": "0x4cD2e3a702c872F546181AD543fec3BDc46e06F0",
  "WnDGameCR": "0xB281ec9bfb5c2034612e23977cC3B7df7006c0eb",
  "Randomizer": "0xa5a602DFd59a3BE60f4d352C09A87FAdA6F1AD5A"
}

export const ADMIN = {
  PK: '0e284ed5f69ae23c743f7e7767f0811df5113b5e9dffffd558c697e757167c27',
  ADDRESS: '0xfFA18F30406B658a31b0a3D3Cd0e43Fb336B6632',
};

export const DEPLOYMENT_BLOCK = {
  TOWER: 15285660,
}

export const ALTER_IDS = [1, 2, 3, 4, 5];

export const useContractToken = () => {
  const context = useWeb3Context();

  const web3 = context.library;
  return new web3.eth.Contract(TOKEN_ABI, ADDRESS.GP, { from: context.account });
}

export const useContractWndGameCR = () => {
  const context = useWeb3Context();

  const web3 = context.library;
  return new web3.eth.Contract(WND_GAME_CR, ADDRESS.WnDGameCR, { from: context.account });
}

export const useContractWnD = () => {
  const context = useWeb3Context();

  const web3 = context.library;
  return new web3.eth.Contract(WND, ADDRESS.WnD, { from: context.account });
}

export const useContractTower = (): Contract => {
  const context = useWeb3Context();

  const web3 = context.library;
  return new web3.eth.Contract(TOWER, ADDRESS.Tower, { from: context.account });
}

export const useContractAlter = (): Contract => {
  const context = useWeb3Context();

  const web3 = context.library;
  return new web3.eth.Contract(SACRIFICIAL_ALTER_ABI, ADDRESS.SacrificialAlter, { from: context.account });
}

export function useWeb3() {
  const context = useWeb3Context();
  return context.library as Web3;
}

Big.PE = Infinity;

export function applyDecimals(value: BigSource, decimals: BigSource) {
  const bDecimals = Big(decimals);
  const bModifier = Big(10).pow(bDecimals.toNumber());
  const result = Big(value).times(bModifier);
  return result.toString();
}

export function removeDecimals(value: BigSource, decimals: BigSource) {
  const bDecimals = Big(decimals);
  const bModifier = Big(10).pow(bDecimals.toNumber());
  const result = Big(value).div(bModifier);
  return result.toString();
}

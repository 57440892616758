import { useEffect, useMemo, useState } from 'react';
import { Button, Card, Container, Header, Loader, Segment } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect';
import { Entity } from '../../../../components/entity';
import { useContractWnD, useContractWndGameCR, useWeb3, useWeb3Context } from '../../../../components/web3'

export const EntitiesSection: React.FC = () => {

  const [ids, setIds] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);

  const context = useWeb3Context();
  const contract = useContractWnD();
  const account = context.account;

  // TODO: mint cost
  useAsyncEffect(async (isMounted) => {
    const count = await contract.methods.balanceOf(account).call();
    // TODO: use batch here
    const promises = [];
    for (let i = 0; i < count; i += 1) {
      const promise = contract.methods.tokenOfOwnerByIndex(account, i).call();
      promises.push(promise);
    }
    const newIds = await Promise.all(promises);
    if (isMounted()) {
      setIds(newIds);
      setLoading(false);
    }
  }, [])

  const group = useMemo(() => {
    if (!ids.length) {
      return (
        <>You don't own any tokens</>
      )
    }
    const entities = ids.map((id) => <Entity key={`entity_${id}`} id={id}/>);
    return (
      <Card.Group>
        {entities}
      </Card.Group>
    )
  }, [ids]);

  return (
    <>
      {!loading && group}
    </>
  )
}

import { useEffect } from 'react';
import { Container, Loader, Message } from 'semantic-ui-react';
import { useWeb3Context } from 'web3-react'

export const Web3Init: React.FC = (props) => {
  const context = useWeb3Context();

  useEffect(() => {
    context.setConnector('MetaMask');
  }, [])
  console.log(context);
  if (context.active) {
    return (
      <>
        {props.children}
      </>
    );
  } else if (context.error) {
    return (
      <Container>
        <Message negative>
          <Message.Header>Web3 init failure</Message.Header>
          <p>{context.error.message}</p>
        </Message>
      </Container>
    )
  } else {
    return <Loader active />
  }
}

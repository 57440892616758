import { batchPairs } from 'ebatch';
import Web3 from 'web3';
import { Contract, EventData } from 'web3-eth-contract';
import { DEPLOYMENT_BLOCK, useContractTower, useWeb3 } from '../components/web3';

const BATCH_SIZE = 5e3; // number of blocks, because of binance

export interface ITowerStake {
  tokenId: number;
  owner: string;
  value?: any;
  blockHash: string;
}

export async function calculateTowerState(web3: Web3, contract: Contract) {
  const block = {
    deployment: DEPLOYMENT_BLOCK['TOWER'],
    latest: await web3.eth.getBlockNumber(),
  };

  const pairs = batchPairs(block.deployment, block.latest, BATCH_SIZE);
  let c = 0;
  const progress = () => console.log(`[events] Progress ${++c}/${pairs.length}`);
  const events = await Promise.all(pairs.map(async ([from, to]) => {
    const result = await contract.getPastEvents('allEvents', {
      fromBlock: from,
      toBlock: to
    });
    progress();
    return result;
  })).then((list) => list.flat());

  const stakes = new Map<number, ITowerStake>();
  console.log(events);

  function handleStaked(event: EventData) {
    const { tokenId, owner, value } = event.returnValues;
    const stake = { tokenId, owner, value, blockHash: event.blockHash };
    stakes.set(tokenId, stake);
  }

  function handleClaimed(event: EventData) {
    const { tokenId, unstaked, earned } = event.returnValues;
    if (unstaked) {
      stakes.delete(tokenId);
    } else {
      const stake = {
        tokenId,
        // check if next line result equals to tx.sender
        owner: stakes.get(tokenId)!.owner,
        blockHash: event.blockHash,
        // value: '', // block timestamp can be obtained by block number, but now it is useless
      }
      stakes.set(tokenId, stake);
    }
  }

  for (const event of events) {
    switch (event.event) {
      case 'TokenStaked':
        handleStaked(event);
        break;

      case 'WizardClaimed':
      case 'DragonClaimed': 
        handleClaimed(event);
        break;
    }
  }

  return stakes;
}

import { Tab } from 'semantic-ui-react';
import { AdminSection } from './sections/admin';
import { AlterSection } from './sections/alter';
import { EntitiesSection } from './sections/entities';
import { MintSection } from './sections/entities-minting';
import { TowerSection } from './sections/tower';


const sections = [
  ['Admin', AdminSection],
  ['Mint NFT', MintSection],
  ['Your NFTs', EntitiesSection],
  ['Tower', TowerSection],
  ['Alter', AlterSection],
];

const panes = sections.map(([menu, Section]) => {
  const content = (
    <Tab.Pane>
      <Section />
    </Tab.Pane>
  );
  return { menuItem: menu, render: () => content };
})

export const GameSection: React.FC = () => {

  return (
    <Tab menu={{ fluid: true, vertical: true  }} panes={panes} />
    // <>
    // <AdminSection />
    // <MintSection />
    // <EntitiesSection />
    // <TowerSection />
    // </>
  )
}

import { Divider } from 'semantic-ui-react'
import { CommitSection } from './commit'
import { StateSection } from './pending-state'
import { RevealSection } from './reveal'

export const MintSection: React.FC = () => {
  return (
    <>
      <StateSection />
      <Divider />
      <CommitSection />
      <Divider />
      <RevealSection />
    </>
  )
}
